import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { trailingslashit, getImgResizeUrl } from "../components/helpers"

import { Parser } from "html-to-react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import CTAs from "../components/CTAs"
import SectionHeading from "../components/SectionHeading"
import Services from "../components/Services.js"
import Hero from "../components/Hero"
import Image from "../components/image"
import SEO from "../components/seo"

const CaseStudyCard = ({ caseStudy, col }) => {
  return (
    <Link to={trailingslashit(`/our-work/${caseStudy.slug}`)}>
      <div className="h-100 d-flex align-items-end p-3 position-relative">
        <Image
          src={caseStudy.fields.featured_image}
          resizeWidth={400}
          className="bg-img"
        />
        <Image
          resizeWidth={300}
          className="img-fluid"
          style={{
            filter: "contrast(0) grayscale(1) brightness(5)",
            maxWidth: "75%",
            minWidth: "50%",
            maxHeight: "70px",
          }}
          src={caseStudy.fields.logo}
          alt={caseStudy.fields.title}
        />
      </div>
    </Link>
  )
}

const HomePage = ({ data, location }) => {
  const home = data.home.edges[0].node
  const webinar = data.webinar.edges[0].node

  return (
    <Layout
      hero={
        <Hero
          heading={home.hero_heading}
          subhead={home.hero_subhead}
          ctas={home.hero_ctas}
          image={home.hero_background}
          visibleByDefault={true}
          video={`/img/hero_video.mp4`}
        />
      }
    >
      <SEO
        title="Custom Software, Web Design, Mobile App, and Digital Strategy"
        path={location.pathname}
      />
      <Container as="section">
        <Row className="text-center text-lg-left my-5 my-lg-0">
          <Col
            lg={{ span: 8, order: 1 }}
            xs={{ span: 12, order: 2 }}
            className="pt-4 pb-3 pb-md-0 pt-lg-0"
          >
            <SectionHeading
              crown={"About Us"}
              heading={home.about_us_heading}
              align="lg-left text-center"
            />
            <p>{home.about_us_body}</p>
            <CTAs ctas={home.about_us_ctas} />
          </Col>
          <Col lg={{ span: 4, order: 2 }} xs={{ span: 12, order: 1 }}>
            <Image
              resizeWidth={600}
              width={600}
              height={401}
              src={home.about_us_image}
              className="img-fluid rounded mt-lg-2 mt-xl-0"
            />
          </Col>
        </Row>
      </Container>
      {/* <Container as="section" id="webinar" className="bg-secondary rounded my-5 mt-md-0 mb-lg-0 p-md-4" style={{boxShadow: "0 15px 15px rgba(0, 0, 0, 0.16)"}}>
        <Row className="text-center text-lg-left py-5  px-sm-2 py-sm-4 px-lg-0 px-xl-4 py-xl-5">
          <Col lg={{ span: 6, order: 1 }} xs={{ span: 12, order: 1 }}>
            <p class="embed-responsive embed-responsive-16by9">
              {new Parser().parse(webinar.summary)}
            </p>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            xs={{ span: 12, order: 2 }}
            className="pt-4 pt-lg-0"
          >
            <SectionHeading
              crown={"About Us"}
              heading={webinar.title}
              align="lg-left text-center"
              className="text-white"
              crownColor="text-white"
            />
            <p className="text-white">
              {new Parser().parse(webinar.body)}
            </p>
          </Col>
        </Row>
      </Container> */}
      <Container as="section">
        <Row className="pb-5 pb-lg-0">
          <Col>
            <Services
              crown={"Our Solutions"}
              heading={home.our_solutions_heading}
              services={home.our_solutions}
            />
            <p className="text-center">
              <CTAs
                ctas={home.our_solutions_ctas}
                className="d-block d-lg-inline"
              />
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="our-work--home">
        <div className="outer-wrapper p-5 text-center text-sm-left position-relative">
          <Image src="/img/airstream.png" className="title-bg" />
          <div className="inner-wrapper">
            <h2 className="text-white">{home.our_work_heading}</h2>
            <p className="text-white">{home.our_work_body}</p>
          </div>
          <a href={trailingslashit("/our-work/")} className="btn btn-primary">
            See Our Work
          </a>
        </div>
        {home.our_work_featured.map(caseStudy => (
          <CaseStudyCard key={caseStudy.slug} caseStudy={caseStudy} col={3} />
        ))}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    home: allButterPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          hero_heading
          hero_subhead
          hero_ctas {
            label
            url
            icon
            type
            color
          }
          hero_background
          about_us_heading
          about_us_body
          about_us_ctas {
            label
            url
            icon
            type
          }
          about_us_image
          our_solutions_heading
          our_solutions {
            body
            heading
            icon
          }
          our_solutions_ctas {
            label
            url
            icon
            type
          }
          our_work_heading
          our_work_body
          our_work_featured {
            slug
            fields {
              title
              device_mockup
              hero_background
              featured_image
              logo
              title
              summary
            }
          }
          our_work_ctas {
            label
            url
            icon
            type
          }
          our_thoughts_heading
        }
      }
    }
    our_work: allButterPage(filter: { page_type: { eq: "case_studies" } }) {
      edges {
        node {
          title
          featured_image
        }
      }
    }
    webinar: allButterPost(
      filter: {
        categories: { elemMatch: { slug: { eq: "webinar" } } }
        status: { eq: "published" }
      }
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          title
          summary
          body
        }
      }
    }
  }
`

export default HomePage
