import React from "react"
import { graphql } from "gatsby"
import CTAs from "./CTAs"

import SectionHeading from "./SectionHeading"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// import CTAs from '../components/CTAs'
import Image from "./image"
import { StaticQuery } from "gatsby"

const Services = ({ crown, heading, data }) => {
  const services = data.services.edges

  return (
    <>
      <SectionHeading crown={crown} heading={heading} className="mb-5" />
      <Row className="mb-0 services">
        {services.map(service => (
          <Col
            key={service.node.title}
            lg={3}
            md={6}
            className="text-center service mb-5 mb-md-0"
          >
            <Image src={service.node.icon} className="mb-4 w-50 mx-auto" />
            <h3 className="h5 mb-3">{service.node.title}</h3>
            <p>{service.node.excerpt}</p>
            <CTAs
              ctas={service.node.our_service_ctas}
              className="service-cta mb-5"
            />
          </Col>
        ))}
      </Row>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        services: allButterPage(filter: { page_type: { eq: "services" } }) {
          edges {
            node {
              icon
              title
              excerpt
              body
              our_service_ctas {
                label
                url
                icon
                type
              }
            }
          }
        }
      }
    `}
    render={data => <Services data={data} {...props} />}
  />
)
